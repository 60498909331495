document.addEventListener("turbolinks:load", () => {
  $("#competition_customer_id").on("change", function () {
    var customerId = $(this).val();
    var competitionLevelsUpdateArea = $("#competition-levels-update-area");

    if (customerId) {
      $.ajax({
        method: "PUT",
        url: "/competitions/update_levels",
        data: { customer_id: customerId },
        dataType: "json",
      })
        .done(function (result) {
          var htmlContent = "";
          competitionLevelsUpdateArea.html(htmlContent);

          $.each(result, function (index, level) {
            htmlContent += `<div class="form-check">
                <input class="form-check-input is-valid check_boxes optional" type="checkbox" value="${level.id}" name="competition[level_ids][]" id="competition_level_ids_${level.id}">
                <label class="form-check-label collection_check_boxes" for="competition_level_ids_${level.id}">${level.name}</label>
              </div>`;
          });

          competitionLevelsUpdateArea.html(htmlContent);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          console.error("AJAX request failed:", textStatus, errorThrown);
        });
    }
  });
});
