window.UserFixture = class {
    constructor() {
      this.usedUsersId = [];
  
      this.fields = $(".user-select-fixtures-options");
  
      this.currentUsedValues = {};
  
      this.fields.each((i, field) => {
        const $field = $(field);
        const fieldId = $field.attr("id"); 
        this.currentUsedValues[fieldId] = $field.val();
      });
  
      this.addBehaviours();
      this.rebuildUsedUsers();
      this.enableSelectsOption();
      this.disableSelectsOption();
    }
  
    addBehaviours() {
      const scope = this;
  
      this.fields.each(function () {
        const $field = $(this);
  
        $field.on("change", function () {
          const fieldId = $field.attr("id");
          scope.currentUsedValues[fieldId] = $field.val();
          scope.rebuildUsedUsers();
          scope.enableSelectsOption();
          scope.disableSelectsOption();
        });
      });
    }
  
    rebuildUsedUsers() {
      this.usedUsersId = [];
  
      $.each(this.currentUsedValues, (key, value) => {
        if (value) {
          this.usedUsersId.push(value);
        }
      });
    }
  
    enableSelectsOption() {
      this.fields.each(function () {
        $(this).find("option").removeAttr("disabled");
      });
    }
  
    disableSelectsOption() {
      const scope = this;
  
      this.fields.each(function () {
        const $field = $(this);
        $.each(scope.usedUsersId, function (j, usedUserId) {
          $field
            .find(`option[value="${usedUserId}"]`)
            .attr("disabled", "disabled");
        });
  
        $field.find("option:selected").removeAttr("disabled");
      });
    }
  }