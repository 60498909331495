import videojs from "video.js";
import "./rewind-and-forward";
import "videojs-thumbnail-sprite";

export default class MarkupPlayer {
  constructor() {
    let videoPlayer = document.querySelector("#videojs-markup");

    
    document.querySelector('#extraTimeInputEvent').addEventListener('click', function(){
        document.querySelector('#extra-time-game').classList.toggle('d-none');
    });

    if (videoPlayer) {
      // Player
      this.player = videojs(videoPlayer);

      // Added video sprites/frames images
      let frames = $("#videojs-markup").data("frames");
      let sprites = []
      let frameStart = 0;

      frames.forEach(frame => {
        sprites.push({
          url: frame,
          start: frameStart,
          duration: 320,
          width: 160,
          height: 90,
          interval: 2,
        });

        frameStart = frameStart + 320;
      });

      this.player.thumbnailSprite({
        sprites: sprites
      });

      // Enable rewind and forward
      this.player.rewindForwardButton({
        forward: 0.1,
        back: 0.1,
      });

      this.player.stepRewindForwardButton({
        forward: 1,
        back: 1,
      });

      this.currentTime = 0;
      this.resetBtn = $("#markup-reset-btn");

      // First start
      this.firstStart = $("#video_first_start");
      this.firstStartTemp = $("#first_start_temp");

      // First end
      this.firstEnd = $("#video_first_end");
      this.firstEndTemp = $("#first_end_temp");

      // Second start
      this.secondStart = $("#video_second_start");
      this.secondStartTemp = $("#second_start_temp");

      // Second end
      this.secondEnd = $("#video_second_end");
      this.secondEndTemp = $("#second_end_temp");

      // First et start
      this.firstEtStart = $("#video_first_et_start");
      this.firstEtStartTemp = $("#first_et_start_temp");

      // First et end
      this.firstEtEnd = $("#video_first_et_end");
      this.firstEtEndTemp = $("#first_et_end_temp");

      // Second et start
      this.secondEtStart = $("#video_second_et_start");
      this.secondEtStartTemp = $("#second_et_start_temp");

      // Second et end
      this.secondEtEnd = $("#video_second_et_end");
      this.secondEtEndTemp = $("#second_et_end_temp");

      //EXTRA TIME
      // First start
      this.extraTimeFirstEnd = $("#video_extra_time_first_start");
      this.extraTimeFirstEndTemp = $("#extra_time_first_start_temp");
      // Second start
      this.extraTimeSecondEnd = $("#video_extra_time_second_start");
      this.extraTimeSecondEndTemp = $("#extra_time_second_start_temp");

      // Current field
      this.currentField = this.firstStartTemp;
      this.currentUpdateField = this.firstStart;

      // Add behaviours
      this.addBehaviours();
      this.fillTempFields();

      let scope = this;
      // Update time on fields
      this.player.on("timeupdate", () => {
        scope.updateTime();
      });
    }
  }

  fillTempFields() {
    // First start
    this.firstStartTemp.val(this.formatHourMinute(this.firstStart.val()));

    // First end
    this.firstEndTemp.val(this.formatHourMinute(this.firstEnd.val()));

    // Second start
    this.secondStartTemp.val(this.formatHourMinute(this.secondStart.val()));

    // Second end
    this.secondEndTemp.val(this.formatHourMinute(this.secondEnd.val()));

    // First et start
    this.firstEtStartTemp.val(this.formatHourMinute(this.firstEtStart.val()));

    // First et end
    this.firstEtEndTemp.val(this.formatHourMinute(this.firstEtEnd.val()));

    // Second et start
    this.secondEtStartTemp.val(this.formatHourMinute(this.secondEtStart.val()));

    // Second et end
    this.secondEtEndTemp.val(this.formatHourMinute(this.secondEtEnd.val()));

    //EXTRA TIME
    // First start
    this.extraTimeFirstEndTemp.val(this.formatHourMinute(this.extraTimeFirstEnd.val()));
    // Second start
    this.extraTimeSecondEndTemp.val(this.formatHourMinute(this.extraTimeSecondEnd.val()));
  }

  addBehaviours() {
    let scope = this;

    // Set focus
    this.currentField.focus();

    // On click
    // First start
    this.firstStartTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.firstStart;
    });

    // First end
    this.firstEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.firstEnd;
    });

    // Second start
    this.secondStartTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.secondStart;
    });

    // Second end
    this.secondEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.secondEnd;
    });

    // First et start
    this.firstEtStartTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.firstEtStart;
    });

    // First et end
    this.firstEtEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.firstEtEnd;
    });

    // Second et start
    this.secondEtStartTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.secondEtStart;
    });

    // Second et end
    this.secondEtEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.secondEtEnd;
    });


    //Extra
    // First start
    this.extraTimeFirstEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.extraTimeFirstEnd;
    });
    // Second start
    this.extraTimeSecondEndTemp.on("click", function () {
      scope.currentField = $(this);
      scope.currentUpdateField = scope.extraTimeSecondEnd;
    });

    // Reset all field
    this.resetBtn.on("click", function (event) {
      event.preventDefault();
      scope.resetFields();
    });
  }

  resetFields() {
    this.currentTime = 0;

    // First start
    this.firstStart.val("");
    this.firstStartTemp.val("");

    // First end
    this.firstEnd.val("");
    this.firstEndTemp.val("");

    // Second start
    this.secondStart.val("");
    this.secondStartTemp.val("");

    // Second end
    this.secondEnd.val("");
    this.secondEndTemp.val("");

    // First et start
    this.firstEtStart.val("");
    this.firstEtStartTemp.val("");

    // First et end
    this.firstEtEnd.val("");
    this.firstEtEndTemp.val("");

    // Second et start
    this.secondEtStart.val("");
    this.secondEtStartTemp.val("");

    // Second et end
    this.secondEtEnd.val("");
    this.secondEtEndTemp.val("");

    //EXTRA TIME
    // First start
    this.extraTimeFirstEnd.val("");
    this.extraTimeFirstEndTemp.val("");
    // Second start
    this.extraTimeSecondEnd.val("");
    this.extraTimeSecondEndTemp.val("");
  }

  updateTime() {
    // Current time
    this.currentTime = this.player.currentTime();

    if (this.currentTime == 0) {
      this.currentTime = parseFloat(this.firstStart.val());
    }

    // Update field with value formatted
    this.currentField.val(this.formatHourMinute(this.currentTime));

    // Update field with value
    this.currentUpdateField.val(this.currentTime);
  }

  formatHourMinute(currentTime) {
    if (currentTime > 0) {
      let minutes = Math.floor(currentTime / 60);
      let seconds = Math.floor(currentTime - minutes * 60);

      let x = minutes < 10 ? "0" + minutes : minutes;
      let y = seconds < 10 ? "0" + seconds : seconds;

      return x + ":" + y;
    } else {
      return "00:00";
    }
  }
}
